let base = {
    menuToggle: function () {
        let btn = document.querySelector('#nav-trigger')
        let menu = document.querySelector('#nav-wrap')
        if (btn && menu) {
            btn.addEventListener('click', () => {
                btn.classList.toggle('open')
                menu.classList.toggle('open')
            }, {
                passive: true
            })
        }
    },
    handleDogModals(dashboard) {
        dashboard.addEventListener('click', (e) => {
            if (e.target.matches('.edit') || e.target.matches('.delete')) {
                e.preventDefault()
                let modalEl = document.querySelector(e.target.getAttribute('data-bs-target'))
                let modal = bootstrap.Modal.getOrCreateInstance(modalEl)
                let modalTitle = modalEl.querySelector('.modal-title')
                let modalBody = modalEl.querySelector('.modal-body')
                let modalSaveBtn = modalEl.querySelector('.modal-footer .btn.btn-primary')

                modalSaveBtn.classList.remove('d-none')
                axios.post(e.target.href).then(response => {
                    let data = response.data.page
                    if (!data) {
                        document.querySelector('html').innerHTML = response.data
                        return false
                    } else {
                        if (!data.dog) {
                            modalTitle.textContent = ''
                            modalBody.innerHTML = data.msg
                            modalSaveBtn.classList.add('d-none')
                            if(data.deleted){
                                let delItem = document.querySelector('#dogData-'+data.deleted)
                                if(delItem){
                                    delItem.classList.add('dogDeleted')
                                    setTimeout(()=>{
                                        delItem.remove()
                                    },300)
                                }

                            }
                        } else {
                            modalTitle.textContent = data.dog + ' editieren'
                            modalBody.innerHTML = data.dogEditForm
                        }
                        modal.show()
                    }
                })
            }
        })
    }
}
document.addEventListener("DOMContentLoaded", function () {
    let dashboard = document.querySelector('.dashboard');

    //global functions
    base.menuToggle()

    if (dashboard) {
        base.handleDogModals(dashboard);
    }
});